<script lang="tsx">
import { defineComponentCoreUiCounter } from '@core/app/components/core/ui/CoreUiCounter.vue'
import { IconMinus, IconPlus } from '#components'

export default defineComponentCoreUiCounter({
    slots: {
        minusIcon: () => <IconMinus size="xs" />,
        plusIcon: () => <IconPlus size="xs" />,
    },
})

</script>

<style lang="scss" scoped>
@use "@core-scss/components/CoreUiCounter" as *;

@include counter {
    padding: 0.625rem;
    border-radius: $mon-border-radius;

    @include more-than(lg) {
        padding: 0.625rem 1.25rem;
    }
}

@include input {
    @include mon-font-regular;
    font-size: 1.25rem;
    color: inherit;
    max-width: 2.2rem;
}

@include button {
    height: 1.75rem;
    width: 1.75rem;
    border-radius: 100%;
    background-color: inherit;
    transition: background-color $mon-trans-time-normal $mon-timing, color $mon-trans-time-normal $mon-timing;
}

@include button-hover {
    background-color: $mon-c-primary-300;
    color: $mon-c-white;
}
</style>
